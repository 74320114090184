import React, { useLayoutEffect, useContext } from 'react';
import Layout from '../components/layout';
import { ThemeContext } from '../providers/theme';
import Products from '../components/Products';
import InfoCards from '../components/InfoCard';
import InfoContainer from '../components/InfoContainer';
import SEO from '../components/seo';
import { useStaticQuery, graphql } from 'gatsby';
import { handleProducts } from '../utils/handleProducts';
import Breadcrumbs from '../components/Breadcrumbs';

function RedWine() {
  const { setTheme } = useContext(ThemeContext);

  useLayoutEffect(() => {
    setTheme('redWine');
  }, []);

  const data = handleProducts(
    useStaticQuery(graphql`
      {
        allSitePage(
          filter: {
            context: {
              productType: { eq: "roedvin" }
              isProduct: { eq: "true" }
            }
          }
        ) {
          edges {
            node {
              path
              context {
                productTitle
                productPrice
                productOldprice
                productLink
                productImage
                productDescription
                productCategory
                productAvailable
                productType
              }
            }
          }
        }
      }
    `)
  );

  return (
    <React.Fragment>
      <SEO
        title={'Rødvin'}
        description={
          'Find den helt rigtige rødvin. Vi samler udvalg i samarbejde med en håndfuld af landets vinforhandlere, for at gøre det nemt for dig at finde den helt rette flaske'
        }
      />
      <Layout themeColor="#722f37" title="Rødvin">
        <Products type="rødvin" data={data} />
        <InfoCards
          firstColumn={{
            title: 'Rødvin til enhver smag',
            text:
              'Hos Vinkammeret bestræber vi os på at præsentere et bredt udvalg af de bedste rødvine fra hele verden, fordi vi ved det kan være svært at vælge hvilken vin man skal have. Du kan finde et bredt udvalg af alle slags vine, alt fra lette rødvine til intense og kraftige rødvine, på den måde er der noget for enhver smag.'
          }}
          secondColumn={{
            title: 'Forskellige druesorter',
            text:
              'Der findes tusindvis af forskellige druetyper, som bliver dyrket rundt omkring i verden. På vinkammeret.dk kan du finde alle de bedste vine, alt lige fra Pinot Noir, Merlot, Cabernet Sauvignon, Zinfandel, Malbec og Syrah. '
          }}
          thirdColumn={{
            title: 'Rødvin fra forskellige lande',
            text:
              'Der produceres rødvin overalt i verden. Der bliver både produceret i de mere traditionelle vinlande som Italien, Spanien, Frankrig og Portugal, men også i andre lande i Europa, i Afrika, Australien, Asien samt Syd- og Nordamerika, som hver især producerer helt unikke rødvine.'
          }}
        />
        <InfoContainer
          title={'Vidste du...'}
          text={
            'At rødvin er danskernes foretrukne vin, hele 75 % foretrækker et glas rødvin frem for eksempelvis hvidvin. Rødvin produceres af mørke bær, helt nøjagtigt blå vindruer, som giver en rød farve og nogle gange næsten sort farve. Når man producerer vinen, så presser og gærer man drueskallerne, som så udskiller farvestoffer, tanniner og smagsstoffer. \n\n Gæringsprocessen varierer alt efter hvor dyr eller billig vinen skal være. De billigere vine gærer i kortere tid, måske 2-3 dage, hvor dyrere vin gærer i længere tid. Man gærer typisk rødvin med temperaturer mellem 25-30 grader. Hvis man gærer ved lavere temperatur, får man en mere frugtig vin. \n\nMan kan lagre rødvinen på forskellige måder, inden man flasker vinen. Hvis man ønsker en mere frugtig og ren rødvin kan man bruge enten en ståltank, cementtank eller store fade, de har hver deres fordel. For eksempel er ståltanke lette at holde helt rene samt er lettere at temperaturstyre. Dog er ulempen ved denne metode at en ståltank er lufttæt og vin har godt af en smule ilt. Ilt kan man få ved enten at bruge cementtanke eller træfade, da de ikke er 100% lufttætte. '
          }
        />
        <Breadcrumbs type={'roedvin'} />
      </Layout>
    </React.Fragment>
  );
}

export default RedWine;
